import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
// import { Zoom } from "react-awesome-reveal";

import { ProductCategoryModel } from '../../servermodels/productCategoryModel';
import { sorterByOrder } from '../../services/sortUtils';
import { ProductCategory } from './productCategory';
import { NodeModel } from '../../servermodels/nodeModel';
import { ProductLinks } from './productLinks';


const ProductCategories: React.FC = () => {

    const queryData: NodeModel<ProductCategoryModel>[] = useStaticQuery(graphql`
        query ProductCategoriesQuery {
          allStrapiProductCategory {
            edges {
              node {
                id
                Title
                Picture {
                  alternativeText
                  caption
                  localFile {
                    publicURL
                  }
                }
                SeoUrl
                MetaKeywords
                MetaDescription
                DetailContent {
                  ... on STRAPI__COMPONENT_PAGE_BLOCKS_IMAGES {
                    strapi_component
                    id
                  }
                  ... on STRAPI__COMPONENT_PAGE_BLOCKS_OFFER_BUTTON {
                    id
                    strapi_component
                    OfferButton
                  }
                  ... on STRAPI__COMPONENT_PAGE_BLOCKS_RICH_TEXT {
                    id
                    strapi_component
                    childStrapiComponentPageBlocksRichTextContentTextnode {
                      Content
                    }
                  }
                  ... on STRAPI__COMPONENT_PAGE_BLOCKS_TWO_COLUMNS_RICH_TEXT {
                    id
                    strapi_component
                    ContentLeft {
                      data {
                        ContentLeft
                      }
                    }
                    ContentRight {
                      data {
                        ContentRight
                      }
                    }
                  }
                }
                IsNew
                Order
                UrlMore
                Content {
                  data {
                    Content
                  }
                }
              }
            }
          }
        }
    `).allStrapiProductCategory.edges;

    const productCategories = queryData.map(d => d.node)
    productCategories.sort(sorterByOrder);

    let numberOfRow = 0;

    return (
        <>
          <ProductLinks productCategories={productCategories} />
            {productCategories.map((c) => {
                const isOdd = (++numberOfRow % 2) === 1;

                return (
                    <ProductCategory key={c.id} isOdd={isOdd} category={c} />
                );
            })}
        </>
    );
}

export default ProductCategories;