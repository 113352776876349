import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProductCategories from '../components/indexPage/productCategories';
import { SpdContainer } from '../components/spadacka';
import { VerticalLine } from '../components/verticalLine';
import { MainReferences } from '../components/indexPage/mainReferences';
import { graphql, useStaticQuery } from 'gatsby';
import { HomePageHeaderModel } from '../servermodels/homePageHeaderModel';

type Query = {
    node: HomePageHeaderModel
}

const HomePage: React.FC = () => {
    const data: Query[] = useStaticQuery(graphql`
        query HomePageQuery {
            allStrapiHomePageHeader {
                edges {
                node {
                    Content {
                    data {
                        Content
                        id
                    }
                    }
                }
                }
            }
        }
    `).allStrapiHomePageHeader.edges;

    const homePageHeader = data.map(d => d.node)[0]

    return (
        <Layout hideBreadcrumbs={true}>
            <SEO title="Home page" isHomepage />
            <div className="hero">
                <div className="hero__box container">
                    <div className="company">
                        <SpdContainer>
                            <div className="company--header">
                                <div className="company--perex" dangerouslySetInnerHTML={{ __html: homePageHeader.Content.data.Content }}>
                                    {/* <p><strong>Ofsetová a digitální tiskárna.</strong><br />Tiskneme pro vás už od roku <b>1992</b>.<br />Nabízíme Vám naše <b>zkušenosti</b> a komplexní řešení.</p> */}
                                </div>
                            </div>
                        </SpdContainer>
                    </div>
                </div>
            </div>

            <div className="container">
                <MainReferences />
            </div>

            <div className="container">
                <h1 className="main-title-hp">
                    <span>Produkty</span>
                </h1>
                <VerticalLine topSelector=".main-title-hp span" bottomSelector="footer" />
                <div className="col">
                    <ProductCategories />
                </div>
            </div>
        </Layout>
    );
}

export default HomePage;