import React from 'react';
import { useInView } from 'react-intersection-observer';
import { ProductCategoryModel } from '../../servermodels/productCategoryModel';
import HtmlContent from '../htmlcontent';
import { ProductCategoriesMoreLink } from './productCategoriesMoreLink';

type CategoryProps = {
    isOdd: boolean;
    category: ProductCategoryModel;
}
export const ProductCategory: React.FC<CategoryProps> = ({ isOdd, category }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    const cls = inView ? 'reveal-zoom-in' : 'reveal-zoom-in-start';

    return (
        <div className={cls} ref={ref}>
            <div className="row">
                <div className={`product-item ${(isOdd ? 'odd' : '')} ${(category.IsNew ? 'new' : '')}`}>
                    {isOdd ?
                        <>{renderImg(category)}</>
                    : null}
                        
                    <div className="product-item__content">
                        <h2>{category.Title}</h2>
                        <HtmlContent htmlContent={category.Content?.data?.Content} />
                        <div>
                            <ProductCategoriesMoreLink data={category} className={`btn ${(category.IsNew ? 'btn--secondary' : 'btn--primary')} product-item__show`}>Více</ProductCategoriesMoreLink>
                        </div>
                    </div>

                    {!isOdd ?
                        <>{renderImg(category)}</>
                    : null}
                    {category.IsNew ?
                        <div className="product-item__badge">Novinka</div>
                        : null}
                </div>
            </div>
        </div>
    )
}

function renderImg(data: ProductCategoryModel) {
    return (
        <div className="product-item__img">
            <ProductCategoriesMoreLink data={data}>
                <img src={data.Picture.localFile.publicURL} title={data.Title} alt={data.Picture.alternativeText} />
            </ProductCategoriesMoreLink>
        </div>
    );
}