import React, { useEffect, useState } from 'react';
import { getEventSystem } from '../../events';

type Props = {
    topSelector: string,
    bottomSelector: string,
    color?: string,
    // centerOfSelector?: string,
}

type State = {
    top: number,
    height: number,
}

export const VerticalLine: React.FC<Props> = (props) => {

    const [pos, setPos] = useState<State>({ top: 0, height: 0 });

    function recalcHeight() {
        const topEl = document.querySelector(props.topSelector);
        const bottomEl = document.querySelector(props.bottomSelector);
        if (!topEl || !bottomEl) {
            return;
        }

        const rectBody = document.body.getBoundingClientRect();
        const rectTop = topEl.getBoundingClientRect();
        const rectBottom = bottomEl.getBoundingClientRect();

        // if (props.centerOfSelector) {

        //     const centerEl = document.querySelector(props.centerOfSelector);
        //     const rectCenter = centerEl?.getBoundingClientRect();
        // }

        setPos({
            top: rectTop.bottom - rectBody.top,
            height: rectBottom.top - rectBody.top,
        });
    }

    useEffect(() => {
        const eventSystem = getEventSystem();

        eventSystem.on('resize', recalcHeight);

        recalcHeight();

        return () => {
            eventSystem.removeListener('resize', recalcHeight);
        };
    }, []);

    let strokeWidth = 3;
    let center = strokeWidth / 2;
    const color = props.color || 'var(--primary)';

    return (
        <div className="vl" style={{ top: pos.top }}>
            <svg width={`${strokeWidth}`} height={`${pos.height}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <line x1={center} y1="0" x2={center} y2={pos.height} strokeWidth={strokeWidth} stroke={color} />
                </g>
            </svg>
        </div>
    );
}