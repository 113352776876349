import { Link } from 'gatsby';
import React, { FC } from 'react'
import { ProductCategoryModel } from '../../servermodels/productCategoryModel';
import { productCategoryUrl } from '../../services/productCategoryUrl';


type Props = {
  productCategories: ProductCategoryModel[]
}

export const ProductLinks: FC<Props> = ({ productCategories }) => {
  const prods = productCategories.filter(p => !p.IsNew)

  return (
    <div className="product-links">
      {prods.map(c =>
        <span key={c.id} className="product-links_item">
          <Link to={productCategoryUrl(c)}>{c.Title}</Link>
        </span>
      )}
    </div>
  )
}